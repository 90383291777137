import * as React from "react"
import Layout from "../components/layout"
import SEO from '../components/SEO'
import Hero from '../components/hero'
import { useStaticQuery, graphql } from 'gatsby'
import Banner from "../components/banner"

const ChaptersPage = () => {
  const { heroImage, site } = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "chapters.jpg" }) {
				childImageSharp {
					fluid(quality: 95) {
						...GatsbyImageSharpFluid
					}
				}
			}
      site {
        siteMetadata {
          email
        }
      }
    }
  `)
  const email = site.siteMetadata.email
  const chapters = ["Thomas Jefferson High School for Science and Technology", "Wayzata High School", "Mission San Jose High School", "Parkland High School", "duPont Manual High School", "Roslyn High School", "Scripps Ranch High School", "Scarsdale High School"]

  return (
    <Layout>
      <SEO title='Chapters' description="Start a Biology Bowl chapter at your school to get access to benefits like study resources and support from our team." />
      <Hero image={heroImage} title="Chapters" />
      {/* <div className='mx-auto px-4 sm:px-6 pt-10'>
        <div className="text-center py-10 mx-auto pt-8 mb-4 bg-gray-100 rounded-lg px-4 shadow-sm">
          <h1 className="text-2xl tracking-tight leading-10 sm:leading-none font-extrabold sm:text-3xl lg:text-4xl xl:text-5xl sm:mb-4">
            Start a Chapter
          </h1>
          <p className='mb-2 text-lg text-gray-500'>Start a Biology Bowl chapter at your school and gain access to practice material, support, and more from our team!</p>
          <br />
          <a href="https://forms.gle/qu485dfohHBHaXLu8" target="_blank" className="bg-blue-500 hover:bg-blue-400 focus:outline-none focus:bg-blue-400 px-5 py-3 border border-transparent text-base font-medium rounded-md text-white transition duration-150 ease-in-out">Start a Chapter</a>
        </div>
      </div> */}
      <div className="max-w-screen-md mx-auto px-4 sm:px-6 pt-10">
        <div class="bg-teal-100 border-teal-500 rounded text-teal-900 px-4 py-3 shadow-md" role="alert">
          <div class="flex items-center">
            <div class="py-1"><svg class="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
            <div>
              <p><strong>Notice:</strong> We are currently revamping our Chapters. Stay tuned!</p>
              {/* <p class="text-sm">Make sure you know how these changes affect you.</p> */}
            </div>
          </div>
        </div>
      </div>
      <div className='max-w-screen-md mx-auto py-10 px-4 sm:px-6 block text-lg'>
        <div className='mb-16 text-gray-500'>
          <h1 className="text-2xl tracking-tight leading-10 sm:leading-none font-extrabold text-gray-900 sm:text-3xl lg:text-4xl xl:text-4xl mb-4 sm:mb-6">
            What Are Chapters?
          </h1>
          <p className='mb-2'>We want <em>you</em> to create a Biology Bowl chapter 
          at your school! Your chapter could be a new club at your school or a subdivision of an 
          existing club (e.g. Biology Olympiad club). Becoming a chapter gives the club numerous benefits
           from the IBB. Moreover, it spreads interest in biology and emphasizes collaboration amongst 
           chapter members!
           <br/>
           <br/>
           If you require assistance in making a biology club or IBB chapter at your school, please reach out to us at <a target='_blank' className='text-blue-600 hover:underline' href='mailto:internationalbiologybowl@gmail.com'>internationalbiologybowl@gmail.com</a>.
           </p> 
          {/* <ul className='list-disc list-inside mb-4 list'>
            <li>Chapters with <strong>ten or more people</strong> will become <strong>designated chapters</strong>. They can request <strong>practice material, sponsorship or prizes, and individual support</strong>.</li>
            <li>Chapters with <strong>fifteen or more people</strong> will become designated chapters and be <strong>featured on our website</strong>. They can request <strong>practice material, sponsorships or prizes, and individual support</strong>.</li>
          </ul>
          <p>
          ‍All designated chapters in the 2021-2022 school year will have <strong>priority in sign-ups and chapter benefits</strong> in the 2022-2023 school year.
          </p> */}
        </div>
        <div className='text-gray-500 mb-16'>
          <h1 className="text-2xl tracking-tight leading-10 sm:leading-none font-extrabold text-gray-900 sm:text-3xl lg:text-4xl xl:text-4xl mb-4 sm:mb-6">
            Chapter Expectations
          </h1>
          <ul className='list-disc list-inside mb-4 list'>
            <li>Must be a new or existing biology-related club</li>
            <li>Chapter captains should teach and train the rest of their team/chapter in the biological sciences to allow for the overall growth of all members</li>
            <li>Chapter captains should promote IBB events and competitions to its members and within the school as a whole through means including, but not limited to, social media, physical flyers, word-of-mouth, etc. </li>
            {/* <li>Must have <strong>at least 5 International Biology Bowl competitors</strong> in order to be designated as a chapter.</li>
            <li>All chapter captains must <strong>teach and train</strong> the rest of their team for the International Biology Bowl. (Preparing for competitions such as your country's Biology Olympiad counts as teaching & training.)</li>
            <li>Chapters can be run inside of existing biology-related clubs or a new Biology Bowl club.</li> */}
          </ul>
        </div>
        <div className='text-gray-500'>
          <h1 className="text-2xl tracking-tight leading-10 sm:leading-none font-extrabold text-gray-900 sm:text-3xl lg:text-4xl xl:text-4xl mb-4 sm:mb-6">
            Chapter Benefits
          </h1>
          <ul className='list-disc list-inside mb-4 list'>
            <li>Access to unused and unreleased USABB and IBB Bowl Round questions along with answers.</li>
            <li>Access to online presentations about various topics from biology clubs across the nation in the material tested.</li>
            <li>Access to online handouts about various topics in the material tested.</li>
            <li>The chapter will be featured on the IBB website.</li>
          </ul>
        </div>
        
        {/* <div className='text-gray-500'>
          <h1 className="text-2xl tracking-tight leading-10 sm:leading-none font-extrabold text-gray-900 sm:text-3xl lg:text-4xl xl:text-4xl mb-4 sm:mb-6">
            Current Chapters
          </h1>
          <ul className='list-disc list-inside mb-4 list'>
            {chapters.map(chapter => {
              return (
                <li>{chapter}</li>
              )
            })}
          </ul>
        </div> */}
      </div>
      <Banner />
    </Layout>
  )
}

export default ChaptersPage
